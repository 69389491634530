/*---------------------------
 ** CSS Reset
-----------------------------*/
html {
  font-size: 100%;
  box-sizing: border-box;
  /* Set core root defaults */
}
html:focus-within {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
:root {
  --white: hsl(0, 0%, 100%);
  --blue: hsl(223, 87%, 63%);
  --light-blue: hsla(223, 87%, 63%, 0.715);
  --pale-blue: hsl(223, 100%, 88%);
  --light-red: hsl(354, 100%, 66%);
  --gray: hsl(0, 0%, 59%);
  --very-dark-blue: hsl(209, 33%, 12%);
}

body {
  font-family: "Libre Franklin", sans-serif;
}

.component {
  max-width: 40.625rem;
}
@media (max-width: 71.8125em) {
  .component {
    margin: 1.25rem;
  }
}
.component .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.component .header__logo-box {
  margin-bottom: 3.5625rem;
  align-self: center;
}
.component .header__text-box {
  margin-bottom: 2.625rem;
  align-self: center;
  text-align: center;
}
.component .header__main {
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-size: 2.8125rem;
  color: var(--gray);
}
.component .header__main span {
  font-weight: 700;
  color: var(--very-dark-blue);
}
.component .header__sub {
  font-weight: 300;
  font-size: 1.125rem;
}
.component .header__main {
  margin-bottom: 1.5rem;
}
.component .header__search {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 1.25rem;
}
.component .header__box {
  width: 100%;
}
.component .header__input {
  padding: 1.125rem;
  border-radius: 1.875rem;
  color: var(--gray);
  border: 1px solid var(--pale-blue);
  width: 100%;
}
.component .header__button {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  background-color: var(--blue);
  padding: 1.125rem;
  width: 30%;
  border-radius: 1.875rem;
  text-align: center;
}
.component .header__button:hover {
  background-color: var(--light-blue);
}
.component .header__error {
  display: none;
  margin-top: 0.3125rem;
  margin-left: 2rem;
  color: var(--light-red);
}
.component .header__error--shown {
  display: inline;
}
.component .image-box {
  margin-bottom: 4.375rem;
  width: 100%;
}
.component .image-box .illustration-dashboard {
  max-width: 100%;
}

@media (max-width: 39.9375em) {
  .component .header__main {
    font-size: 1.875rem;
  }
  .component .header__sub {
    font-weight: 300;
    font-size: 0.875rem;
  }
  .component .header__search {
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
  }
  .component .header__button {
    margin-top: 1rem;
    width: 100%;
  }
  .component .header__error {
    margin-left: 0;
    text-align: center;
  }
}
.footer__icons {
  justify-content: center;
  display: flex;
  gap: 1.875rem;
  list-style: none;
  margin-bottom: 1.25rem;
  cursor: pointer;
}
.footer__icons li {
  color: var(--blue);
  border: 0.0125rem solid var(--pale-blue);
  padding: 5px 11px;
  border-radius: 50%;
  transition: all 0.2s;
}
.footer__icons li:hover {
  color: #fff;
  background-color: var(--blue);
}
.footer__copyright {
  text-align: center;
  margin-bottom: 1.25rem;
  color: var(--gray);
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}