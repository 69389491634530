/*---------------------------
 ** CSS Reset
-----------------------------*/
// See https://piccalil.li/blog/a-modern-css-reset/

html {
  font-size: 100%;
  box-sizing: border-box;
  /* Set core root defaults */
  &:focus-within {
    scroll-behavior: smooth;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
// @media (prefers-reduced-motion: reduce) {
//   html:focus-within {
//     scroll-behavior: auto;
//   }

//   *,
//   *::before,
//   *::after {
//     transition-duration: 0.01ms !important;
//     animation-duration: 0.01ms !important;
//     animation-iteration-count: 1 !important;

//     scroll-behavior: auto !important;
//   }
// }
